import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  Body,
  Headline2,
  colours,
  typographyStyles,
  vertical,
  horizontal,
} from "design-kit"

export const Copy = styled(Body)`
  margin: 0 0 ${vertical.xs};
`

export const SectionHeading = styled(Headline2)`
  ${typographyStyles.headline3};
  margin: 0 0 ${vertical.s};
`

export const Section = styled.section`
  margin-bottom: ${vertical.l};
`

const listStyles = css`
  ${typographyStyles.body};
  margin: 0 ${horizontal.l} ${vertical.s};

  li {
    margin: 0 0 ${vertical.xxs};
  }
`

export const Ol = styled.ol`
  ${listStyles}
  list-style-type: decimal;
`

export const Ul = styled.ul`
  ${listStyles}
  list-style-type: disc;
`

export const Table = styled.table`
  ${typographyStyles.body};
  margin-top: ${vertical.m};
  margin-bottom: ${vertical.m};
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: solid ${colours.greyScale.grey50} 1px;
`

export const Thead = styled.thead`
  ${typographyStyles.bodyBold};
  background-color: ${colours.greyScale.grey25};
`

export const Th = styled.th`
  text-align: left;
  background-color: ${colours.greyScale.grey25};
  border: solid ${colours.greyScale.grey50} 1px;
  padding: ${vertical.xxs} ${horizontal.m};
`

export const Td = styled.td`
  border: solid ${colours.greyScale.grey50} 1px;
  padding: ${vertical.xxs} ${horizontal.m};
`
