import { FC } from "react"
import styled from "@emotion/styled"
import { breakpoints } from "design-kit"

import { Section, SectionHeading } from "./Common"

import {
  ArrowCardContainer,
  ArrowCardData,
} from "../../shared-components/ArrowCardContainer/ArrowCardContainer"

const arrowCardsItems: ArrowCardData[] = [
  {
    articleTitle: "Mortgages explained",
    description:
      "If you’re a first time buyer, here’s everything you need to know in plain English and jargon-free.",
    buttonHref: "https://www.habito.com/home/first-time-buyer-mortgages",
  },
  {
    articleTitle: "Buy-to-let mortgages",
    description:
      "Whether you’re a long-term landlord or you just want to rent a place to a friend, you’ll need a buy-to-let mortgage.",
    buttonHref: "https://www.habito.com/home/buy-to-let-mortgages",
  },
  {
    articleTitle: "Mortgage in principle",
    description:
      "See how an MIP can help you house-hunt with confidence – and how it’s different from an agreement in principle.",
    buttonHref: "/mortgage-in-principle",
  },
]

const Inner = styled.div`
  ${breakpoints.tablet`
    display: flex;
    justify-content: space-between;
  `}
`

const ExplainerCards: FC = () => (
  <Section>
    <SectionHeading>
      Tools &amp; advice for your mortgage journey:
    </SectionHeading>

    <Inner>
      <ArrowCardContainer cards={arrowCardsItems} />
    </Inner>
  </Section>
)

export default ExplainerCards
