import { FC } from "react"

import { TextLink } from "design-kit"

import {
  Table,
  Thead,
  Th,
  Td,
  Section,
  SectionHeading,
  Copy,
  Ul,
} from "./Common"

const ScotlandRates: FC = () => (
  <Section>
    <SectionHeading>Scotland stamp duty rates (LBTT rate)</SectionHeading>

    <Copy>
      The equivalent of stamp duty in Scotland is the Land and Buildings
      Transaction Tax, or LBTT. It’s calculated in ‘bands’, which means you pay
      different rates on different ‘portions’ of the property price.
    </Copy>

    <Table>
      <Thead>
        <tr>
          <Th>Property price</Th>
          <Th>Stamp duty rate</Th>
        </tr>
      </Thead>

      <tbody>
        <tr>
          <Td>£0 – £145,000</Td>
          <Td>0%</Td>
        </tr>
        <tr>
          <Td>£145,001 – £250,000</Td>
          <Td>2%</Td>
        </tr>
        <tr>
          <Td>£250,001 – £325,000</Td>
          <Td>5%</Td>
        </tr>
        <tr>
          <Td>£325,001 – £750,000</Td>
          <Td>10%</Td>
        </tr>
        <tr>
          <Td>£750,001+</Td>
          <Td>12%</Td>
        </tr>
      </tbody>
    </Table>

    <Copy>
      <strong>Here’s an example for a property worth £300,000:</strong>
    </Copy>

    <Ul>
      <li> You’ll pay 0% on the first £145,000 = £0</li>
      <li> Then 2% on the next £105,000 = £2,100</li>
      <li> Finally, 5% on the last £50,000 = £2,500</li>
    </Ul>

    <Copy>Total LBTT = £4,600</Copy>

    <Copy>
      You can also use{" "}
      <TextLink
        href="https://revenue.scot/calculate-tax/calculate-property-transactions#calculator"
        text="Revenue Scotland's LBTT calculator"
      />
      .
    </Copy>
  </Section>
)

export default ScotlandRates
