import React from "react"
import styled from "@emotion/styled"
import {
  BodySmall,
  InfoIcon,
  breakpoints,
  colours,
  horizontal,
  vertical,
} from "design-kit"

const Container = styled.a`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  display: inline-block;
  cursor: pointer;

  &:hover > div {
    visibility: visible;
    cursor: pointer;
  }
`

const TextContainer = styled.div`
  position: absolute;
  right: -${horizontal.xs};
  bottom: calc(100% + ${vertical.xxs});
  width: calc(100vw - ${horizontal.l});
  max-width: 250px;
  visibility: hidden;
  background-color: ${colours.white};
  color: ${colours.offBlack};
  border: 1px solid ${colours.greyScale.grey50};
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  padding: ${vertical.xs} ${horizontal.s};
  box-shadow: 0px 4px 0px rgba(214, 219, 232, 0.4);

  ${breakpoints.tablet`
    max-width: 300px;
  `}
`

const SpacedBodySmall = styled(BodySmall)`
  margin: 0 0 ${vertical.xxs};
`

export const Tooltip: React.FC<{ copy: React.ReactNode }> = ({ copy }) => {
  return (
    <Container>
      <InfoIcon color={colours.action.main} />
      <TextContainer>
        <SpacedBodySmall>{copy}</SpacedBodySmall>
      </TextContainer>
    </Container>
  )
}
