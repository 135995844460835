import { FC } from "react"

import { TextLink } from "design-kit"

import { Section, SectionHeading, Copy } from "./Common"

const FirstTimeRates: FC = () => (
  <Section>
    <SectionHeading>First time buyer stamp duty rates</SectionHeading>

    <Copy>
      <strong>
        In England and Northern Ireland, first time buyers get a discount on
        stamp duty
      </strong>
      , as long as the property is worth £625,000 or less. You pay no tax on the
      first £425,000, then 5% on the portion from £425,001 to £625,000.
    </Copy>

    <Copy>
      <strong>In Scotland, first time buyers get a discount on LBTT</strong>:
      You pay no tax on the first £175,000 of the property price, rather than
      the standard £145,000. And if your property is more expensive, you still
      benefit from the tax relief.{" "}
      <TextLink
        href="https://www.which.co.uk/money/mortgages-and-property/home-movers/stamp-duty/lbtt-stamp-duty-in-scotland-a03c59y60hgs#headline_3"
        text="More about LBTT for first time buyers"
      />
      .
    </Copy>

    <Copy>
      <strong>In Wales, first time buyers don’t get any LTT relief</strong>,
      unlike in England and Scotland where you get some relief on stamp duty and
      LBTT.{" "}
    </Copy>
  </Section>
)

export default FirstTimeRates
