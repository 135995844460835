import React from "react"
import styled from "@emotion/styled"
import {
  Card,
  HeadlineBold,
  PrimaryCTA,
  Spinner,
  borderRadii,
  breakpoints,
  colours,
  horizontal,
  typographyStyles,
  vertical,
} from "design-kit"

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: wrap;
`

const DetailsContainer = styled(Card)`
  border-color: ${colours.greyScale.grey50};
  box-sizing: content-box;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: ${vertical.xs} ${horizontal.s};
  ${breakpoints.tablet`
    padding: ${vertical.s} ${horizontal.m};
  `}
  ${breakpoints.desktop`
    padding: ${vertical.m} ${horizontal.l};
  `}
`

export const Details: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <div>
    <DetailsContainer element="div">
      <ItemsContainer>{children}</ItemsContainer>
    </DetailsContainer>
  </div>
)

// This component primarily exists to ensure the `constructive.main` colour
// bleeds up around the rounded corners at the bottom of the `DetailsContainer`
// -- otherwise, we'd have some ugly corners.
export const WholeCalculatorContainer = styled.div`
  background-color: ${colours.constructive.main};
  border-radius: ${borderRadii.s};
`

const Box = styled.div`
  background-color: ${colours.constructive.main};
  border-radius: 16px;
`

const PaddingLarge = styled.div`
  padding: ${vertical.xs} ${horizontal.s};

  ${breakpoints.tablet`
    padding: ${vertical.s} ${horizontal.m};
  `}

  ${breakpoints.desktop`
    padding: ${vertical.m} ${horizontal.l};
  `}
`

const FirstResultFlexBox = styled.div`
  padding: ${vertical.xs} 0;
  margin-top: ${vertical.xs};

  ${breakpoints.tablet`
    padding: 0;
    flex-grow: 1;
  `}
`

const StampDutyAmount = styled.p`
  ${typographyStyles.headline2}
`

export const ResultPanel: React.FC<{
  headline: string
  stampDutyValue: null | string
}> = props => (
  <Box>
    <PaddingLarge>
      <div>
        <HeadlineBold>{props.headline}</HeadlineBold>
        <FirstResultFlexBox>
          {props.stampDutyValue ? (
            <StampDutyAmount>{props.stampDutyValue}</StampDutyAmount>
          ) : (
            <Spinner />
          )}
        </FirstResultFlexBox>
      </div>
    </PaddingLarge>
  </Box>
)

export const ButtonContainer = styled.div`
  padding: 24px 0px 0px 0px;
  max-width: 150px;
`

export const CalculateButton: React.FC<{
  calculate: () => void
}> = ({ calculate }) => {
  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      calculate()
    },
    [calculate]
  )
  const width = React.useMemo(
    () =>
      ({
        mobile: "full-width",
        tablet: "content-width",
        desktop: "content-width",
      }) as const,
    []
  )
  return (
    <ButtonContainer>
      <PrimaryCTA
        type="button"
        text="Calculate"
        onClick={onClick}
        width={width}
      />
    </ButtonContainer>
  )
}
