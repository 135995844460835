import { FC } from "react"

import { Section, SectionHeading, Copy } from "./Common"

const Disclaimer: FC = () => (
  <Section>
    <SectionHeading>This is just an estimate!</SectionHeading>

    <Copy>
      Tax rules change all the time and everyone’s circumstances are different,
      so don’t take anything on this page as advice! When it’s time to buy,
      speak to your solicitor for advice on stamp duty.
    </Copy>
  </Section>
)

export default Disclaimer
