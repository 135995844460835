import { FC } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import {
  Body,
  BodyBold,
  Card as DKCard,
  SecondaryLink,
  breakpoints,
  colours,
  vertical,
} from "design-kit"

const FlexItemContainer = styled.div`
  width: 100%;

  ${breakpoints.tablet`
    width: calc(33.3% - 16px);
  `}
`

const ArticleFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoints.tablet`
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  `}
`

const cardColours = [
  colours.callout.main,
  colours.constructive.main,
  colours.destructive.main,
  colours.action.main,
  colours.greyScale.grey50,
  colours.greyScale.grey100,
]

const getColourForId = (i: number): string => {
  const colourId = i % cardColours.length
  return cardColours[colourId]
}

interface ColourCardProps {
  title: string
  content: string
  buttonTitle?: string
  descriptiveTitle?: string
  href?: string
  colour: string
}

interface CardContainerProps {
  colour: string
}

const CardContainer = styled(DKCard)<CardContainerProps>`
  ${props => (props.colour ? `border:  4px solid ${props.colour}` : "")};
  margin-bottom: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${breakpoints.tablet`
    margin-bottom: 0;
  `}
`

const ColourCard: FC<ColourCardProps> = ({
  title,
  content,
  buttonTitle,
  descriptiveTitle,
  href,
  colour,
}) => (
  <CardContainer element="div" colour={colour}>
    <div>
      <BodyBold
        css={css`
          color: ${colours.offBlack};
          margin: 0 0 ${vertical.xs} 0;
        `}
      >
        {title}
      </BodyBold>

      {content && (
        <Body
          css={css`
            color: ${colours.greyScale.grey100};
            margin: 0 0 ${vertical.s} 0;
          `}
        >
          {content}
        </Body>
      )}
    </div>

    {buttonTitle && href && (
      <SecondaryLink
        href={href}
        text={buttonTitle}
        descriptiveText={descriptiveTitle}
      />
    )}
  </CardContainer>
)

export interface ColourCardData {
  articleTitle?: string
  description?: string
  buttonTitle?: string
  buttonDescriptiveTitle?: string
  buttonHref?: string
}

export const ColourCardContainer: FC<{ cards: ColourCardData[] }> = ({
  cards,
}) => (
  <ArticleFlexContainer>
    {cards &&
      cards.map((item, index) => (
        <FlexItemContainer key={index}>
          <ColourCard
            title={item.articleTitle || ""}
            content={item.description || ""}
            buttonTitle={item.buttonTitle || undefined}
            descriptiveTitle={item.buttonDescriptiveTitle || undefined}
            href={item.buttonHref || undefined}
            colour={getColourForId(index)}
          />
        </FlexItemContainer>
      ))}
  </ArticleFlexContainer>
)
