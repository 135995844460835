import { FC } from "react"

import { Section, SectionHeading, Copy, Ol } from "./Common"

const CanIAddToMortgage: FC = () => (
  <Section>
    <SectionHeading>Can I add stamp duty to my mortgage?</SectionHeading>

    <Copy>
      If you’ve forgotten to factor the costs of stamp duty, you could use some
      of your deposit to pay, if you can afford to.
    </Copy>

    <Copy>
      You might be able to borrow more on your mortgage to cover the costs of
      stamp duty, but you’ll have to reduce your deposit so that the deposit and
      mortgage add up to the purchase price.
    </Copy>

    <Copy>
      Usually, it’s not a good idea to increase your mortgage this way, and
      better to pay stamp duty from the savings you have, if possible. That’s
      because adding stamp duty to your mortgage means you’d need to borrow more
      money on your mortgage. And that has two effects:
    </Copy>

    <Ol>
      <li>
        <Copy>
          <strong>It could raise your mortgage interest rate</strong>. That’s to
          do with your loan to value (LTV). LTV is a percentage showing how much
          of the property value you’re borrowing. The lower your LTV (meaning,
          the lower your mortgage is as a percent of the total property value),
          the lower interest rates you get.
        </Copy>

        <Copy>
          Adding your stamp duty to your mortgage would increase your loan to
          value ratio, so you might have to pay more interest on your mortgage –
          which could increase your monthly mortgage payments.
        </Copy>
      </li>

      <li>
        <Copy>
          <strong>
            It could mean you end up paying far more stamp duty in the long term
          </strong>
          . Your mortgage term (the years you’ll take to pay back your mortgage)
          is usually around 25 years. If you add stamp duty to your mortgage, it
          means you’ll have to pay back interest on that amount over all those
          years – and that could add up to thousands.
        </Copy>

        <Copy>
          Get advice from your mortgage broker before you make a decision – they
          can help you figure out all the numbers and see which option is right
          for you.
        </Copy>
      </li>
    </Ol>
  </Section>
)

export default CanIAddToMortgage
