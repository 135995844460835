import { FC } from "react"

import { TextLink } from "design-kit"

import {
  Table,
  Thead,
  Th,
  Td,
  Section,
  SectionHeading,
  Copy,
  Ul,
} from "./Common"

const WalesRates: FC = () => (
  <Section>
    <SectionHeading>Wales stamp duty rates (LTT Rate)</SectionHeading>

    <Copy>
      The equivalent of stamp duty in Wales is the Land Transaction Tax, or LTT.
      It’s calculated in ‘bands’, which means you pay different rates on
      different ‘portions’ of the property price.
    </Copy>

    <Table>
      <Thead>
        <tr>
          <Th>Property price</Th>
          <Th>Stamp duty rate</Th>
        </tr>
      </Thead>

      <tbody>
        <tr>
          <Td>£0 – £225,000</Td>
          <Td>0%</Td>
        </tr>
        <tr>
          <Td>£225,001 – £400,000</Td>
          <Td>6%</Td>
        </tr>
        <tr>
          <Td>£400,001 – £750,000</Td>
          <Td>7.5%</Td>
        </tr>
        <tr>
          <Td>£750,001 – £1,500,000</Td>
          <Td>10%</Td>
        </tr>
        <tr>
          <Td>£1,500,001+</Td>
          <Td>12%</Td>
        </tr>
      </tbody>
    </Table>

    <Copy>
      <strong>Here’s an example for a property worth £300,000:</strong>
    </Copy>

    <Ul>
      <li> You’ll pay 0% on the first £225,000 = £0</li>
      <li> Then 6% on the next £75,000 = £4,500</li>
    </Ul>

    <Copy>Total LBTT = £4,500</Copy>

    <Copy>
      You can also use the{" "}
      <TextLink
        href="https://services.wra.gov.wales/land-transaction-tax-calculator"
        text="Welsh Revenue Authority's LBTT calculator"
      />
      .
    </Copy>
  </Section>
)

export default WalesRates
