import { FC } from "react"

import { Section, SectionHeading, Copy, Ul } from "./Common"

const HowThisCalculated: FC<{ formattedPropertyValue: string | null }> = ({
  formattedPropertyValue,
}) => (
  <Section>
    <SectionHeading>
      How is stamp duty calculated
      {formattedPropertyValue ? ` on a ${formattedPropertyValue} home` : ``}?
    </SectionHeading>

    <Copy>How much stamp duty you pay depends on a few things:</Copy>
    <Ul>
      <li>
        Where in the UK you’re buying: England & Northern Ireland, Scotland, or
        Wales
      </li>
      <li>Whether you’re a first time buyer</li>
      <li>
        If you’re buying an additional property (for example, to let out or use
        as a holiday home)
      </li>
      <li>The value of your property</li>
    </Ul>
  </Section>
)

export default HowThisCalculated
