import React from "react"
import styled from "@emotion/styled"
import { Tooltip } from "./Tooltip"
import { BodyBold, GBPInput, colours, horizontal, vertical } from "design-kit"

const Container = styled.div`
  position: relative;
  margin-top: 24px;
`

const Title = styled(BodyBold)`
  margin: 0;
  padding: 0 ${horizontal.s} ${vertical.xs} 0;
  color: ${colours.offBlack};
`

type InputContainerProps = {
  title?: React.ReactNode
  tooltip?: React.ReactNode
  children?: React.ReactNode
}

export const InputContainer: React.FC<InputContainerProps> = ({
  title,
  tooltip,
  children,
}) => {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      {tooltip && <Tooltip copy={tooltip} />}
      {children}
    </Container>
  )
}

type CurrencyInputProperties = {
  value: number
  onUpdate: (_: number) => void
}

export const CurrencyInput: React.FC<CurrencyInputProperties> = ({
  value,
  onUpdate,
}) => {
  const useValue = (n: number): void => {
    onUpdate(Math.min(n, 10000000))
  }

  const onChange = (s: string) => {
    const parsed = parseFloat(s)
    useValue(isNaN(parsed) ? 0 : parsed)
  }

  return (
    <GBPInput
      isError={value < 1}
      onInput={onChange}
      onBlur={() => useValue(value)}
      value={value > 0 ? value.toString() : ""}
      precision="integral"
    />
  )
}
