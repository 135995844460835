import { FC, ReactNode, useState } from "react"
import styled from "@emotion/styled"
import { ExpandableCard, TextLink, vertical } from "design-kit"

import { Copy, Section, SectionHeading } from "./Common"

interface FAQItem {
  id: string
  title: string
  content: ReactNode
  isExpanded: boolean
}

const faqItems = (expandedId: string | null): FAQItem[] => [
  {
    id: "a",
    isExpanded: expandedId === "a",
    title: "Who pays stamp duty?",
    content: (
      <div>
        <Copy>
          It’s only ever the person buying who pays stamp duty – not the seller.
          So if you’re the buyer, you usually send the money to your solicitor
          and they handle the payment to{" "}
          <TextLink
            href="https://www.gov.uk/guidance/pay-stamp-duty-land-tax#2"
            target="_blank"
            rel="noopener noreferrer"
            text="HMRC"
          />{" "}
          or{" "}
          <TextLink
            href="https://www.revenue.scot/land-buildings-transaction-tax/guidance/how-to/online-lbtt-return"
            target="_blank"
            rel="noopener noreferrer"
            text="Revenue Scotland"
          />{" "}
          for you. Your solicitor will usually stay on top of it and remind you
          when it’s time to pay… but legally it’s still your responsibility.
        </Copy>
      </div>
    ),
  },
  {
    id: "b",
    isExpanded: expandedId === "b",
    title: "When do you pay stamp duty?",
    content: (
      <div>
        <Copy>
          Once you complete, you’ll have 14 days to pay stamp duty. After that,
          you might have to pay interest, or a fine, or both.
        </Copy>
      </div>
    ),
  },
  {
    id: "c",
    isExpanded: expandedId === "c",
    title:
      "Do you still get stamp duty relief if you’re buying with someone else?",
    content: (
      <div>
        <Copy>
          If you’re both first time buyers, yes: you’ll get stamp duty relief.
          Bear in mind the amount of relief you get doesn’t double, even if
          there’s two of you.
        </Copy>

        <Copy>
          The rules change based on whether or not you’re married. If you’re
          married, you both need to be first time buyers to get stamp duty
          relief. If you’re not married, then to get the relief, everyone named
          on the mortgage has to be a first time buyer.
        </Copy>
      </div>
    ),
  },
  {
    id: "d",
    isExpanded: expandedId === "d",
    title: "Shared ownership and stamp duty relief",
    content: (
      <div>
        <Copy>
          If you’re a first-time buyer, you still get stamp duty relief if
          you’re buying a shared ownership home worth up to £500,000.
        </Copy>

        <Copy>
          Be aware that if you ‘staircase’, which means buying more shares in
          your property later, you’ll have to pay stamp duty once you own 80% of
          the place or more.{" "}
          <TextLink
            href="https://www.gov.uk/guidance/sdlt-shared-ownership-property#paying-sdlt-in-stages-and-buying-further-shares"
            text="More about staircasing and SDLT"
          />
          .
        </Copy>
      </div>
    ),
  },
]

const FaqWrapper = styled.div`
  margin-bottom: ${vertical.xl};
  padding-top: ${vertical.xs};
`

const FAQSection: FC = () => {
  const [expandedId, setExpandedId] = useState<string | null>(null)

  return (
    <Section>
      <SectionHeading>Stamp duty FAQ</SectionHeading>

      <Copy>
        What could be more exciting than mortgages? That’s right… mortgage tax.
        Here’s a handy FAQ to help you make sense of stamp duty.
      </Copy>

      <FaqWrapper>
        <ExpandableCard
          items={faqItems(expandedId)}
          onChange={id => {
            setExpandedId(id === expandedId ? null : id)
          }}
        />
      </FaqWrapper>
    </Section>
  )
}

export default FAQSection
